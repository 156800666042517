<template>
  <div class="flex h-screen">
    <div class="m-auto">
      <img
        class="h-12 w-auto m-auto"
        src="@/assets/large_logo_estaly_black.png"
        alt="Estaly logo"
      />
      <div class="mt-4 flex" v-if="loading">
        <Spinner />
        <p class="text-green-600" v-if="successMessage">
          {{ $t(successMessage) }}
        </p>
        <p v-else>{{ $t("Deductible payment loading") }}</p>
      </div>
      <div class="mt-4" v-else>
        <p class="text-red-600">{{ $t(errorMessage) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from "@/api/services/customer";
import Spinner from "@/components/svg/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      encryptedId: null,
      loading: true,
      errorMessage: null,
      stripeUrl: null,
      successMessage: null,
    };
  },

  mounted() {
    this.fillEncryptedId();

    // Delay the fetching of the url to let the user read the message before being redirected
    setTimeout(this.fetchStripePaymentUrl, 2000);
  },

  methods: {
    fillEncryptedId() {
      this.encryptedId = this.$route.query.encrypted_id;
    },

    async fetchStripePaymentUrl() {
      try {
        const response =
          await CustomerService.getClaimDeductiblePaymentStripeUrl(
            this.encryptedId
          );
        this.stripeUrl = response.data.url;
        if (this.verifyStripeLink()) {
          this.redirectToStripe();
        }
      } catch (error) {
        this.handleErrorMessage(error);
        console.error(error);
      }
    },

    verifyStripeLink() {
      // Very basic check, we just want to be sure to redirect the user to Stripe
      return this.stripeUrl.startsWith("https://checkout.stripe.com/");
    },

    redirectToStripe() {
      this.successMessage =
        "Deductible payment found, redirecting to our partner Stripe...";
      setTimeout(() => {
        window.location.replace(this.stripeUrl);
      }, 2000);
    },

    handleErrorMessage(error) {
      this.loading = false;
      if (error.response?.status === 404) {
        this.errorMessage =
          "No deductible found, please verify that the link is correct.";
      } else if (error.response?.status === 400) {
        this.errorMessage =
          "No deductible awaiting your action, if you just paid it you can close this window.";
      } else {
        this.errorMessage =
          "Unknown error, please try again later or contact our support.";
      }
    },
  },
};
</script>
