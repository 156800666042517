<template>
  <div class="w-1/2 flex flex-col md:flex-row justify-center">
    <p class="text-2xl font-bold py-4">
      Nous recherchons pour vous nos meilleures offres
    </p>
    <button
      class="disabled inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm rounded-md text-primary bg-transparent disabled:cursor-wait disabled:opacity-75"
    >
      <Spinner />
      Chargement...
    </button>
  </div>
</template>

<script>
import Spinner from "@/components/svg/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {};
  },
};
</script>
