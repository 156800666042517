<template>
  <div v-if="success">
    <div v-if="availableDiscount" class="fixed top-0 w-full z-10 mb-10">
      <Discount :discount="this.discount" />
    </div>
    <div
      :class="[availableDiscount ? 'pt-14 md:pt-10' : '', 'absolute h-full w-full flex flex-col']"
      :style="{ 'background-color': backgroundColor }"
    >
      <div class="grid grid-cols-1 lg:grid-cols-2">
        <div class="py-6 px-8 sm:px-28" :style="{ 'background-color': backgroundColor }">
          <div>
            <div>
              <img class="h-8 w-auto" :src="this.storeLogoUrl" alt="Merchant-logo" />
            </div>
          </div>
          <div class="pt-4 pr-12 pb-8">
            <div class="text-3xl font-semibold" :style="{ color: textColor }">
              {{ this.marketing.headline }}
            </div>
          </div>
          <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
            <form @submit="handleSubmit($event, submitLeadCreation)" ref="leadCreationForm">
              <div class="flex-1">
                <CategorySelector
                  :categories="this.categories"
                  :textColor="this.textColor"
                  :marketplace="this.marketplace"
                  @selectProductCategory="setProductCategory"
                />
              </div>

              <div class="flex space-x-4">
                <div class="flex-1">
                  <label for="productName" class="block text-sm font-medium" :style="{ color: textColor }">
                    {{ $t("Product name") }}
                  </label>
                  <div class="mt-1">
                    <Field
                      id="productName"
                      name="productName"
                      type="text"
                      class="shadow-sm bg-transparent block w-full sm:text-sm rounded-3xl focus:ring-0 focus:border-current placeholder-gray-400"
                      :style="{ color: textColor }"
                      :placeholder="exampleProductTitle"
                      v-model="leadInfo.productName"
                    />
                    <ErrorMessage name="productName" class="mt-2 text-sm text-red-500" />
                  </div>
                </div>
              </div>

              <div class="flex-1 my-4">
                <label for="productDate" class="block text-sm font-medium" :style="{ color: textColor }">
                  {{ $t("Purchase date") }}
                </label>
                <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
                  <input
                    type="date"
                    id="productDate"
                    name="productDate"
                    v-model="leadInfo.productDate"
                    :max="new Date().toISOString().split('T')[0]"
                    :style="{ color: textColor }"
                    class="placeholder-gray-400 bg-transparent border sm:text-sm block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-0 dark:focus:border-current rounded-3xl focus:ring-0 focus:border-current"
                  />
                </div>
              </div>

              <div class="flex flex-col my-4 space-x-0 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row">
                <div class="flex-1">
                  <label for="productPrice" class="block text-sm font-medium" :style="{ color: textColor }">
                    {{ $t("Product price") }}
                  </label>
                  <div class="mt-1 relative shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span class="text-gray-500 sm:text-sm">€</span>
                    </div>
                    <Field
                      id="productPrice"
                      name="productPrice"
                      type="text"
                      class="pl-7 pr-12 shadow-sm bg-transparent focus:ring-white block w-full sm:text-sm rounded-3xl focus:ring-0 focus:border-current placeholder-gray-400"
                      :style="{ color: textColor }"
                      placeholder="3000.0"
                      aria-describedby="price-currency"
                      v-model="leadInfo.productPrice"
                    />
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span class="sm:text-sm" id="price-currency" :style="{ color: textColor }">EUR</span>
                    </div>
                  </div>
                  <ErrorMessage name="productPrice" class="mt-2 text-sm text-red-500" />
                </div>

                <div class="flex-1">
                  <label for="productReference" class="block text-sm font-medium" :style="{ color: textColor }">
                    {{ $t("Product reference") }}
                  </label>
                  <div class="mt-1">
                    <Field
                      id="productReference"
                      name="productReference"
                      type="text"
                      class="shadow-sm bg-transparent block w-full sm:text-sm rounded-3xl focus:ring-0 focus:border-current placeholder-gray-400"
                      :style="{ color: textColor }"
                      placeholder="6007041"
                      v-model="leadInfo.productReference"
                    />
                    <ErrorMessage name="productReference" class="mt-2 text-sm text-red-500" />
                  </div>
                </div>
              </div>

              <div class="flex flex-col my-4 space-x-0 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row">
                <div class="flex-1">
                  <label for="email" class="block text-sm font-medium" :style="{ color: textColor }">
                    {{ $t("Email address") }}
                  </label>
                  <div class="mt-1">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      class="shadow-sm bg-transparent block w-full sm:text-sm rounded-3xl focus:ring-0 focus:border-current placeholder-gray-400"
                      :style="{ color: textColor }"
                      placeholder="example@gmail.com"
                      v-model="leadInfo.email"
                    />
                    <ErrorMessage name="email" class="mt-2 text-sm text-red-500" />
                  </div>
                </div>
                <div class="flex-1">
                  <label for="phoneNumber" class="block text-sm font-medium" :style="{ color: textColor }">
                    {{ $t("Phone number") }}
                  </label>
                  <div class="mt-1">
                    <Field
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      class="shadow-sm bg-transparent block w-full sm:text-sm rounded-3xl focus:ring-0 focus:border-current placeholder-gray-400"
                      :style="{ color: textColor }"
                      placeholder="+33 6 99 49 75 90"
                      v-model="leadInfo.phoneNumber"
                    />
                    <ErrorMessage name="phoneNumber" class="mt-2 text-sm text-red-500" />
                  </div>
                </div>
              </div>

              <div class="pt-5 flex space-x-2 sm:space-x-0 justify-between">
                <div>
                  <button
                    type="submit"
                    class="rounded-xl inline-flex items-center border border-white px-4 py-2 text-sm font-medium text-white shadow-lg hover:bg-white focus:outline-none focus:ring-2"
                    :style="{
                      color: buttonColor,
                      'background-color': buttonBackgroundColor,
                    }"
                    :disabled="isLoading"
                  >
                    {{ $t("Get a quote") }}
                  </button>
                </div>
                <div>
                  <a
                    class="rounded-xl inline-flex items-center border px-4 py-2 text-sm font-medium shadow-md cursor-pointer"
                    :style="{
                      color: textColor,
                    }"
                    :href="contractDetailsLink"
                    target="_blank"
                  >
                    {{ $t("Warranty details") }}
                  </a>
                </div>
              </div>
            </form>
          </VeeForm>
        </div>
        <div>
          <div class="h-full" style="max-height: 42rem">
            <img class="hidden sm:block h-full w-full object-cover" :src="backgroundImageUrl" />
          </div>
        </div>
      </div>
      <div class="bg-white">
        <OffersDetails :marketing="marketing" :iconBackgroundColor="iconBackgroundColor" :iconColor="iconColor" />
      </div>
      <div class="bg-gray-100 py-12 sm:p-12">
        <Footer />
      </div>
    </div>
  </div>
  <PageNotFound v-if="storeNotFound" />
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import LeadService from "../api/services/lead";
import { mapActions, mapMutations } from "vuex";
import OffersDetails from "@/components/OffersDetails.vue";
import CategorySelector from "@/components/CategorySelector.vue";
import Footer from "@/components/subscription/Footer";
import PageNotFound from "@/components/PageNotFound.vue";
import Discount from "@/components/subscription/Discount";
import { isPresent, isObjectValid, isStringValid, isArrayNotEmpty } from "@/utils/validation";

export default {
  components: {
    PageNotFound,
    Field,
    VeeForm,
    ErrorMessage,
    OffersDetails,
    Footer,
    CategorySelector,
    Discount,
  },
  data: () => {
    const schema = yup.object({
      productName: yup.string().required("Product name is required"),
      productPrice: yup.string().required("Product price is required"),
      productReference: yup.string().required("Product reference is required"),
      email: yup.string().required("Email is required."),
      phoneNumber: yup
        .string()
        .matches(/^(?:(?:\00|[+])33|0)\s*[6|7](?:[\s.-]*\d{2}){4}$/, "Please enter a valid mobile phone number.")
        .matches(
          /^(?:(?:\00|[+])33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
          "Invalid phone number. Supported prefixes are + 33 or 0."
        )
        .required("Phone number is required."),
    });

    return {
      leadInfo: {},
      storeLogoUrl: "",
      exampleProductTitle: "",
      success: false,
      storeNotFound: false,
      schema,
      backgroundColor: "",
      textColor: "",
      buttonColor: "",
      buttonBackgroundColor: "",
      marketing: "",
      iconBackgroundColor: "",
      iconColor: "",
      productCategory: "",
      categories: [],
      discount: undefined,
      isLoading: false,
    };
  },

  beforeMount() {
    this.getStore();
  },

  mounted() {
    const query = this.$route.query;
    this.leadInfo.productName = query.productName;
    this.leadInfo.productReference = query.productReference;
    this.leadInfo.email = query.email;
    this.leadInfo.phoneNumber = query.phoneNumber;
    this.leadInfo.productDate = query.productDate;
    if (isPresent(query.productPrice)) this.leadInfo.productPrice = parseFloat(query.productPrice);
  },

  watch: {
    productCategory(newProductCategory) {
      this.getMarketingDetails(newProductCategory);
    },
  },

  computed: {
    contractDetailsLink() {
      const category = isArrayNotEmpty(this.categories)
        ? this.categories.find((category) => category.value === this.productCategory)
        : null;
      return isObjectValid(category) ? category.marketingPage : null;
    },
    availableDiscount() {
      return isObjectValid(this.discount.title);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    setProductCategory(selectedProductCategory) {
      this.productCategory = selectedProductCategory;
    },

    async getMarketingDetails(productCategory) {
      const storeShopName = this.$route.params.storeShop;
      const response = await LeadService.getMarketingDetails(productCategory, storeShopName);
      this.marketing = response.data.store.marketingDetails.marketing;
      this.exampleProductTitle = response.data.store.exampleProductTitle;
    },

    async getStore() {
      const storeShopName = this.$route.params.storeShop;

      try {
        const response = await LeadService.getStore(storeShopName);
        this.discount = response.data.store.discount;
        this.category = response.data.store.category;
        this.exampleProductTitle = response.data.store.exampleProductTitle;
        this.storeLogoUrl = response.data.store.marketingDetails.logoUrl;
        this.backgroundColor = response.data.store.marketingDetails.backgroundColor;
        this.textColor = response.data.store.marketingDetails.textColor;
        this.backgroundImageUrl = response.data.store.marketingDetails.backgroundImageUrl;
        this.buttonColor = response.data.store.marketingDetails.buttonColor;
        this.buttonBackgroundColor = response.data.store.marketingDetails.buttonBackgroundColor;
        this.marketing = response.data.store.marketingDetails.marketing;
        this.iconBackgroundColor = response.data.store.marketingDetails.iconBackgroundColor;
        this.iconColor = response.data.store.marketingDetails.iconColor;
        this.marketplace = response.data.store.marketplace;
        this.categories = response.data.store.categories;
        this.success = true;
        this.storeNotFound = false;
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong.",
              text: error.response.data.error,
            });
            break;
          case 404:
            this.storeNotFound = true;
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong.",
              text: error.response.data.error,
            });
        }
      }
    },

    async submitLeadCreation() {
      this.isLoading = true;
      const params = {
        store: {
          store_shop_name: this.$route.params.storeShop,
        },
        lead: {
          customer: {
            email: this.leadInfo.email,
            phone: this.leadInfo.phoneNumber,
          },
          product: {
            title: this.leadInfo.productName,
            price: this.leadInfo.productPrice,
            category: this.productCategory,
            reference_id: this.leadInfo.productReference,
            currency_code: "EUR",
          },
          invitation_sent_by_estaly: false,
          transaction_date: this.leadInfo.productDate,
          source_channel: "public",
        },
      };
      try {
        if (this.transactionDateValid()) {
          const response = await LeadService.createPublicLead(params);
          this.setIsLoading(false);
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
          const uniqueToken = response.data.lead.estaly_id;
          this.$router.push(`/subscription/${uniqueToken}`);
        } else {
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);

          this.notify({
            category: "simple",
            type: "error",
            title: "Something went wrong",
            text: "Please provide a valid transaction date.",
          });
        }
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            setTimeout(() => {
              this.isLoading = false;
            }, 3000);
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong.",
              text: error.response.data.message,
            });
        }
      }
    },

    transactionDateValid() {
      return isStringValid(this.leadInfo.productDate);
    },
  },
};
</script>
