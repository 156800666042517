import { plainAxiosInstance, securedAxiosInstance } from "../axios";
import { NEW_PASSWORD_URL } from "./constants";

class UserService {
  getUser() {
    return plainAxiosInstance.get("/user");
  }

  updatePassword({ oldPassword, newPassword, newPasswordConfirmation }) {
    return securedAxiosInstance.put(NEW_PASSWORD_URL, {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    });
  }

  updateProfile(params) {
    return securedAxiosInstance.put("/profile", params);
  }

  forgotPassword(email) {
    return plainAxiosInstance.get("/user/password_resets/new", { params: { email } });
  }
}

export default new UserService();
