<template>
  <div
    class="min-h-screen min-w-screen flex sm:items-center justify-center py-0 md:py-12 px-0 md:px-4 sm:px-6 lg:px-8"
  >
    <div
      class="bg-white w-full md:w-1/2 lg:w-1/3 lex items-center justify-center py-20 px-4 md:px-12 rounded-md shadow-2xl"
    >
      <div class="w-full space-y-8">
        <div>
          <a href="https://estaly.co" target="_blank">
            <img
              class="mx-auto h-14 w-auto"
              src="@/assets/large_logo_estaly_alternative.png"
              alt="Estaly"
            />
          </a>
          <h2 class="mt-6 text-center text-3xl font-light text-gray-900">
            Connectez-vous à
          </h2>
          <h2 class="text-center text-3xl font-light text-gray-900">
            votre espace personnel Estaly
          </h2>
          <h3 class="mt-6 text-sm font-light text-gray-700">
            Accédez à votre espace dédié pour gérer votre compte et tous vos
            contrats Estaly en toute simplicité.
          </h3>
        </div>
        <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
          <form
            class="mt-8 space-y-6"
            @submit="handleSubmit($event, handleSignin)"
          >
            <Field type="hidden" name="remember" value="true" />
            <div class="rounded-md shadow-sm -space-y-px">
              <div>
                <label
                  for="email-address"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t("Email address") }}</label
                >
                <div class="my-2">
                  <Field
                    id="email-address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    placeholder="Saisir une adresse email"
                    v-model="user.email"
                  />

                  <ErrorMessage
                    name="email"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
              <div>
                <div class="mt-4">
                  <label
                    for="password"
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >{{ $t("Password") }}</label
                  >
                  <div class="my-2 flex">
                    <Field
                      id="password"
                      name="password"
                      autocomplete="current-password"
                      :type="showPassword ? 'text' : 'password'"
                      required=""
                      class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-bl-md rounded-tl-md rounded-r-none focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-r-0"
                      placeholder="Entrez votre mot de passe"
                      v-model="user.password"
                    />
                    <div
                      class="cursor-pointer bg-white appearance-none rounded-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-tr-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-l-0 rounded-l-none"
                      @click="toggleShow"
                    >
                      <EyeIcon
                        v-if="showPassword"
                        class="w-6 h-6"
                      />
                      <EyeOffIcon
                        v-else
                        class="w-6 h-6"
                      />
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    class="mt-2 text-sm text-red-600"
                  />
                </div>
              </div>
            </div>
            <div>
              <p>
                <span
                  class="text-sm font-bold cursor-pointer text-primary-dark"
                  @click="handleForgotPassword"
                >
                  Mot de passe oublié ?</span
                >
              </p>
            </div>

            <div>
              <button
                type="submit"
                class="group relative w-full flex justify-center inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 text-button-font-primary bg-primary"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                </span>
                <p class="font-body uppercase text-lg">{{ $t("Sign in") }}</p>
              </button>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
    <div class="absolute bottom-4 right-4 md:right-8">
      <span class="text-sm font-semibold cursor-pointer text-primary-dark">
        <a href="mailto:contact@estaly.co">Nous contacter</a>
      </span>
    </div>
  </div>
</template>

<script>
import User from "../models/user";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions, mapState, mapGetters } from "vuex";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import { useI18n } from "vue-i18n";

export default {
  components: {
    Field,
    VeeForm,
    ErrorMessage,
    EyeIcon,
    EyeOffIcon,
  },

  data() {
    const { t } = useI18n();

    const schema = yup.object({
      email: yup.string().required(t("Email is required")).email(t("Email is wrong")),
      password: yup
        .string()
        .required(t("Password is required"))
        .min(8, t("Password is too short")),
    });

    const emailSchema = yup.object({
      email: yup.string().required(t("Email is required")).email(t("Email is wrong")),
    });

    return {
      user: new User("", ""),
      schema,
      showPassword: false,
      emailSchema,
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapGetters("auth", ["isStore", "isCustomer"]),
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },

  created() {
    if (this.signedIn) {
      this.$router.push("/");
    }
  },

  methods: {
    ...mapActions("auth", ["signin", "signout"]),
    ...mapActions("notifications", ["notify"]),

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async handleSignin() {
      if (this.user.email && this.user.password) {
        try {
          await this.signin(this.user);
          if (this.isStore) {
            this.notify({
              type: "error",
              category: "simple",
              title: "You are trying to connect to the wrong URL.",
              text: "Please try to connect at store.estaly.co",
            });
            await this.signout();
          } else {
            this.$router.push("/plans");
          }
        } catch (error) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 404:
              this.notify({
                type: "error",
                category: "simple",
                title: "Your password is wrong.",
                text: "Please try again.",
              });
          }
        }
      }
    },
    async handleForgotPassword() {
      this.$router.push("/forgot-password");
    },
  },
};
</script>
