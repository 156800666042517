<template>
  <div class="min-h-screen min-w-screen flex sm:items-center justify-center py-0 md:py-12 px-0 md:px-4 sm:px-6 lg:px-8">
    <div
      class="bg-white w-full md:w-1/2 lg:w-1/3 lex items-center justify-center py-20 px-4 md:px-12 rounded-md shadow-2xl"
    >
      <div class="w-full space-y-8">
        <div>
          <img class="mx-auto h-14 w-auto" src="@/assets/large_logo_estaly_alternative.png" alt="Estaly" />
          <h2 class="mt-6 text-center text-3xl font-light text-gray-900">{{ $t("Choose a password") }}</h2>
          <h3 class="mt-6 text-sm font-light text-gray-700">
            {{ $t("Access to your dedicated account") }}
          </h3>
        </div>
        <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
          <form class="mt-8 space-y-6" @submit="handleSubmit($event, submitForm)">
            <div class="rounded-md shadow-sm -space-y-px">
              <div>
                <div class="mt-4">
                  <label for="password" class="block text-sm font-medium leading-6 text-gray-900">
                    {{ $t("Password") }}
                  </label>
                  <div class="my-2 flex">
                    <Field
                      id="password"
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      autocomplete="current-password"
                      required
                      class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-bl-md rounded-tl-md rounded-r-none focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-r-0"
                      placeholder="Entrez votre mot de passe"
                      v-model="password"
                    />
                    <div
                      class="cursor-pointer bg-white appearance-none rounded-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-tr-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-l-0 rounded-l-none"
                      @click="showPassword = !showPassword"
                    >
                      <EyeIcon v-if="showPassword" class="w-6 h-6" />
                      <EyeOffIcon v-else class="w-6 h-6" />
                    </div>
                  </div>
                  <ErrorMessage name="password" class="mt-2 text-sm text-red-600" />
                </div>

                <div class="mt-4">
                  <label for="password" class="block text-sm font-medium leading-6 text-gray-900">
                    {{ $t("Password confirmation") }}
                  </label>
                  <div class="my-2 flex">
                    <Field
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      :type="showPasswordConfirmation ? 'text' : 'password'"
                      required=""
                      autocomplete="current-password-confirmation"
                      class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-bl-md rounded-tl-md rounded-r-none focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-r-0"
                      placeholder="Entrez votre mot de passe"
                      v-model="passwordConfirmation"
                    />
                    <div
                      class="cursor-pointer bg-white appearance-none rounded-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md rounded-tr-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-l-0 rounded-l-none"
                      @click="showPasswordConfirmation = !showPasswordConfirmation"
                    >
                      <EyeIcon v-if="showPasswordConfirmation" class="w-6 h-6" />
                      <EyeOffIcon v-else class="w-6 h-6" />
                    </div>
                  </div>
                  <ErrorMessage name="passwordConfirmation" class="mt-2 text-sm text-red-600" />
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="group relative w-full flex justify-center inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 bg-primary-light text-primary-dark"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                <p class="font-body uppercase text-lg">{{ $t("Activate") }}</p>
              </button>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
    <div class="absolute bottom-4 right-4 md:right-8">
      <span class="text-sm font-semibold cursor-pointer text-primary-dark">
        <a href="mailto:contact@estaly.co">Nous contacter</a>
      </span>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import * as yup from "yup";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    EyeIcon,
    EyeOffIcon,
    Field,
    VeeForm,
    ErrorMessage,
  },
  data() {
    const schema = yup.object({
      password: yup.string().required(this.$t("Password is required")).min(8, this.$t("Password is too short")),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("password"), ""], this.$t("Passwords must match"))
        .required(this.$t("Password confirmation is required")),
    });

    return {
      schema,
      unique_token: this.$route.query.unique_token,
      registration_token: this.$route.query.registration_token,
      password: "",
      passwordConfirmation: "",
      showPassword: false,
      showPasswordConfirmation: false,
    };
  },

  computed: {
    ...mapState("auth", ["currentUser", "signedIn"])
  },

  async created() {
    if (this.signedIn) return this.$router.push("/");
  },

  methods: {
    ...mapActions("auth", ["signup", "signout"]),
    ...mapActions("notifications", ["notify"]),

    async submitForm() {
      try {
        await this.signup({
          unique_token: this.unique_token,
          registration_token: this.registration_token,
          password: this.password,
        });

        this.notify({
          type: "success",
          category: "simple",
          title: this.$t("Your account is activated"),
        });
        this.$router.push("/");
      } catch (error) {
        console.error(error.response);
        this.notify({
          category: "simple",
          type: "error",
          title: this.$t("An error occurred"),
          text: error.response?.data?.error || error.message,
        });
      }
    },
  },
};
</script>
